@import '@angular/material/theming';
@include mat-core();

$custom-primary: mat-palette($mat-blue, 700); // 700 is close to #3067DB
$custom-accent: mat-palette($mat-blue, 700); // 700 is close to #3067DB
$custom-warn: mat-palette($mat-red);
$custom-theme: mat-light-theme($custom-primary, $custom-accent, $custom-warn);

@include angular-material-theme($custom-theme);
.mat-mdc-tab-body-wrapper {
  height: 100%;
}
input[type="checkbox"] {

  accent-color: #3067DB;
  
  }
html, body { height: 100%; }
body { margin: 0; font-family: Roboto, "Helvetica Neue", sans-serif; }
